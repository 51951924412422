import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const IciciUse = () => {
  return (
    <section className="case-study cs-use">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">HOW ICICI USED THE VIDEO</h2>
                    <div className="sub-content mt-5">
                        <ol>
                            <li>To come up with a much better attempt at clarifying the objective of the chatbot contrary to a readable version of the same.</li>
                            <li>To help the audience access information from pan India or any corner of the world.</li>
                            <li>To set up an easily shareable content that quickly explains the product and its benefits in a few minutes.</li>
                            <li>Retain customer attention and generate interest by having users develop a strong emotional connection to the brand.</li>
                        </ol>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default IciciUse
