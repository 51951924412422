import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const IciciChallenges = () => {
  return (
    <section className="case-study cs-challenges">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">CHALLENGES</h2>
                    <div className="sub-content mt-5">
                        <p>Since the turn of digital age, there have been many employment search engines catering to job opportunities across every industry. Due to this huge information pile, a career posting with ICICI would often fail to reach its applicant.</p>
                        <p>On that note, the notion of an on-the-clock, response-oriented recruitment chatbot from ICICI had to take matters into its own hands. Being a relatively new service, the chatbot with the help of an explainer video needed to educate a prospective user and inturn be considered as the most lucrative option.</p>
                        <p>The bank was home to a huge customer base of 37 million users. There was no dearth in challenges.</p>
                        <ul>
                            <li>To come up with a much better attempt at clarifying the objective of the chatbot contrary to a readable version of the same.</li>
                            <li>To help the audience access information from pan India or any corner of the world.</li>
                            <li>To set up an easily shareable content that quickly explains the product and its benefits in a few minutes.</li>
                            <li>Retain customer attention and generate interest by having users develop a strong emotional connection to the brand.</li>
                        </ul>
                        <p>An explainer video was by far the best solution to achieve the above mentioned key targets.</p>
                        <ul>
                            <li>Chatbot was directed at millennials who would rather watch a video than read a content or browse on a website to find job openings.</li>
                            <li>A simulation of the chatbot needs to be shown so that customers are aware of the aesthetics and the functioning even before the download.</li>
                            <li>Establish the chatbot’s presence on Whatsapp, google assistant and Alexa.</li>
                            <li>The video needed to be used for investor pitching, and on the company website, e-mails or the app description page on Playstore.</li>
                        </ul>                        
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default IciciChallenges
