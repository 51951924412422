import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"
import ButtonIcon from "../../icon/buttonIcon"

const IciciMoreCs = () => {
  return (
    <section className="home-cs-listing">
        <Container>
        <Row className="justify-content-center">
            <Col md={8} className="text-center">
            <h2 className="sub-title">
                More Case Studies
            </h2>
            </Col>
        </Row>
        <Row className="wrapper-main">          
            <Col lg={4} className="mt-md-0 mt-3">
                <Link to="/case-studies/hdfc/" className="home-cs-link first">
                    <div className="hcs-img">
                        <StaticImage
                        src="../../../images/case-studies/hdfc/cs-landing-page-featured-HDFC.jpeg"
                        alt="HDFC Bank Explainer Video Case studies | WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                        />
                    </div>
                    <div className="hcs-content branding">
                        <span className="section-tag">Explainer Video<span className="seperator"><span>.</span></span>Banking & FinTech</span>
                        <div className="cs-title">
                        <h3>
                            HDFC Bank
                        </h3>
                        </div>
                        <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read Case Study</span>
                        </div>
                    </div>
                </Link>
            </Col>                    
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/case-studies/nomidman/" className="home-cs-link middle">
                    <div className="hcs-img">
                        <StaticImage
                        src="../../../images/case-studies/nomidman/cs-nomidman.jpg"
                        alt="Nomidman Explainer Video UX/UI Case study| WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                        />
                    </div>
                    <div className="hcs-content branding">
                        <span className="section-tag">Explainer Video<span className="seperator"><span>.</span></span>Financial Services</span>
                        <div className="cs-title">
                        <h3>
                            Nomidman
                        </h3>
                        </div>
                        <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read Case Study</span>
                        </div>
                    </div>
                </Link>
            </Col>
            <Col lg={4} className="mt-lg-0 mt-3">
                <Link to="/case-studies/gwc-logistics/" className="home-cs-link last">
                    <div className="hcs-img">
                        <StaticImage
                        src="../../../images/case-studies/gwc-logistics/cs-gwc-logistics-1.jpg"
                        alt="GWC Logistics Explainer Video Case study| WowMakers"
                        placeholder="blurred"
                        width={507}
                        height={354}
                        className="img-fluid"
                        />
                    </div>
                    <div className="hcs-content branding">
                        <span className="section-tag">Explainer Video<span className="seperator"><span>.</span></span>Logistics</span>
                        <div className="cs-title">
                        <h3>
                            GWC Logistics
                        </h3>
                        </div>
                        <div className="btn wowaction-btn">
                        <ButtonIcon />
                        <span>Read Case Study</span>
                        </div>
                    </div>
                </Link>
            </Col>
        </Row>
        </Container>
    </section>
  )
}

export default IciciMoreCs
