import React from "react"
import SeoHead from "../../components/global/seoHead"
import Layout from "../../components/global/layout"
import Seo from "../../components/global/seo"

import ScrollIndicationHeightZero from "../../components/global/scrollIndicationHeightZero"
import IciciMain from "../../components/case-studies/icici-bank/iciciMain"
import IciciInfo from "../../components/case-studies/icici-bank/iciciInfo"
import IciciAbout from "../../components/case-studies/icici-bank/iciciAbout"
import IciciChallenges from "../../components/case-studies/icici-bank/iciciChallenges"
import IciciProcess from "../../components/case-studies/icici-bank/iciciProcess"
import IciciUse from "../../components/case-studies/icici-bank/iciciUse"
import IciciResults from "../../components/case-studies/icici-bank/iciciResults"
import IciciMoreCs from "../../components/case-studies/icici-bank/iciciMoreCs"
import DirectContact from "../../components/global/directContact"

import csIciciFeaturedImage from "../../../static/case-studies/icici-bank/ICICI-social-share.jpeg"

import "../../sass/pages/case-studies/cs-global.scss"
import "../../sass/pages/case-studies/icici-bank.scss"

export const Head = () => (
  <>
    <SeoHead
      ogImage={csIciciFeaturedImage}
      title="ICICI Case Study | 2D Animation Explainer Video | Banking"
      description="How we helped ICICI Bank to promote their career  Chatbot through a viral Explainer Video"
    />
  </> 
)

const IciciBank = () => {
    return (
      <Layout>
        <Seo
          bid="cs-icici-bank"
          bclass="case-studies-page"
        ></Seo>
        <ScrollIndicationHeightZero />
        <IciciMain />
        <IciciInfo />
        <IciciAbout />
        <IciciChallenges />
        <IciciProcess />
        <IciciUse />
        <IciciResults />
        <IciciMoreCs />
        <DirectContact />
      </Layout>
    )
}
export default IciciBank
