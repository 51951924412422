import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import IciciAnimationVideo1 from "../../../images/case-studies/icici-bank/ICICI-chatbot-animation-video-1.mp4"
import IciciAnimationVideo2 from "../../../images/case-studies/icici-bank/ICICI-chatbot-animation-video-2.mp4"
import IciciAnimationVideo3 from "../../../images/case-studies/icici-bank/ICICI-chatbot-animation-video-3.mp4"
import IciciAnimationVideo4 from "../../../images/case-studies/icici-bank/ICICI-chatbot-animation-video-4.mp4"
import IciciAnimationAudio from "../../../images/case-studies/icici-bank/ICICI-bank-background-music-wowmakers.mp3"
import { Col, Container, Row } from "react-bootstrap"

const IciciProcess = () => {
  return (
    <section className="case-study cs-process">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Our Process</h2>
                    <div className="sub-content mt-5">
                        <p>Our account executive establishes the first point of contact between the client and us.</p>
                        <p>A questionnaire was later sent to ICICI as it is done with all our new clients.</p>
                        <h3>Questionnaire</h3>
                        <p>It has questions like:</p>
                        <StaticImage src="../../../images/case-studies/icici-bank/questionnaire-1.png" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={800} height={458}/>
                        <StaticImage src="../../../images/case-studies/icici-bank/questionnaire-2.png" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={458}/>
                        <p className="mt-5">These answers gave us important insight into the schematics of making an explainer video for ICICI and their large customer base. Our creative director then set up a meeting with the officials at ICICI to understand the brand and its specific needs for the video. After this stage, we agreed that a 2D animation explainer video would be the best choice. Soon after, we focused on sharing ideas and coming up with the general storyline and art direction for the video.</p>
                        <h3>Scripting</h3>
                        <p>We believe that the key ingredient to a high quality video always lies in a good script.</p>
                        <p>Our creative director and scripting team tried to push all the right buttons to craft a script that delivers the correct message clearly and compellingly for the target audience, all based on the marketing goals.</p>
                        <p>The ideal target audience for recruitment chatbot were millennials. Therefore, we had to create an output that matched the expectations of the customer base.</p>
                        <p>In the table below you get a detailed rundown of our entire thought process during the scripting process.</p>
                        <table class="table">
                            <thead>
                                <tr class="header">
                                    <th class="text-center mb-0"><p className="text-white">What we wanted to do</p></th>
                                    <th class="text-center mb-0"><p className="text-white">How we did it</p></th>
                                    <th class="text-center mb-0"><p className="text-white">Why we did it like that</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                <td>
                                    <p>Tell a story the target audience could relate to</p>
                                </td>

                                <td>
                                    <p>Our target audience was mainly tech-savvy millennials. Hence, our video revolved around the different scenes of their life.</p>
                                </td>
                                <td><p>We put them all together in a moving public transport to establish our target base in one go. And in doing so, we can better communicate our message to the target customers when we tie them to their daily life activities.</p></td>
                                </tr>

                                <tr className="even">
                                    <td><p>Highlight the customers pain points first</p></td>
                                    <td><p>We wanted to highlight the fact that the information age has provided the world with all kinds of digital channels suited for any activity. The apparent dearth in the area of careers and its exploration were then brought into the spotlight.</p></td>

                                    <td><p>With these highlighted pain points, the customers are reminded of the numerous opportunities to explore careers with ICICI in a much more fruitful manner. The viewers watching this scenario will be in a better position to value the solution. </p></td>
                                </tr>
                                <tr>
                                    <td><p>Demonstrate how to use the chatbot and where it is available</p></td>
                                    <td>
                                    <p>We then went on to explain how the chatbot is available 24*7 on all digital channels, from whatsapp, web extensions, to google assistant & alexa.</p></td>
                                    <td><p>We did a simulation or in better words, a preview of how the chatbot works so that the user will get an idea on how to use it.</p></td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            <a href="https://drive.google.com/file/d/1xmIQ6Bpxm_FVlmbA_GHSxGBcHNLkVqsx/view?usp=sharing" target="_blank" rel="noreferrer">Read the final script for the video.</a>
                        </p>
                        <h3>Storyboard</h3>
                        <p>We pry hard on the key elements of what makes the storyboard turn into our end goal: a cool explainer video. Frames, descriptions, voice-overs are the key elements that ultimately help a video gain prominence.</p>
                        <Row>
                            <Col lg={6}>
                                <StaticImage src="../../../images/case-studies/icici-bank/Storyboard-1.jpeg" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={600} height={500}/>
                            </Col>
                            <Col lg={6}>
                                <StaticImage src="../../../images/case-studies/icici-bank/Storyboard-2.jpeg" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={600} height={500}/>
                            </Col>                            
                        </Row>
                        <p>
                            <a href="https://drive.google.com/file/d/1jdlggWGFwbenE8qBucTob_vJBFkc3Uni" target="_blank" rel="noreferrer">Read the final video storyboard here</a>
                        </p>
                        <h3>Illustration and style frames</h3>
                        <p>Once the storyboard is approved by the client, we forward it to the illustrators to create the characters and the digital universe they are in. The newly created illustrations then makeup the style frame for the explainer video. We made sure the ICICI Brand Colors were used extensively in the video to maintain brand consistency with the target audience.</p>
                        <h3>ICICI Color Scheme</h3>
                        <StaticImage src="../../../images/case-studies/icici-bank/ICICI-chatbot-color-scheme.jpeg" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={1407} height={792}/>
                        <h3>Explainer Video Color Scheme</h3>
                        <StaticImage src="../../../images/case-studies/icici-bank/ICICI-chatbot-video-color-scheme.jpeg" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={1408} height={792}/>
                        <p>All the while during character development we made sure that the characters looked and best represented the demographic, i.e. our target audience.</p>
                        <h3>Character Cast:</h3>
                        <StaticImage src="../../../images/case-studies/icici-bank/ICICI-chatbot-video-character-cast.jpeg" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={1407} height={792}/>
                        <h3>Animation</h3>
                        <p>The style frames are then forwarded to the animators who inspect and give life to all these frames.</p>
                        <p>We believe <Link to="/explainer-video">animated explainer videos are very effective as they help to cut through the noise </Link>to capture the attention of the viewer.</p>
                        <p>Another reason why we believe our animations are perfectly capable of arousing the viewer's attention are the transitions that make the video visually appealing.</p>
                        <p>In simple words, now the ideas and the functioning of the product will finally get a context to showcase its performance.</p>
                        <video width="894" height="504" className="vid mb-4" autoPlay={true} loop muted autoplay playsinline>
                            <source src={IciciAnimationVideo1} type="video/mp4" />
                        </video>
                        <video width="894" height="504" className="vid mb-4" autoPlay={true} loop muted autoplay playsinline>
                            <source src={IciciAnimationVideo2} type="video/mp4" />
                        </video>
                        <video width="894" height="504" className="vid mb-4" autoPlay={true} loop muted autoplay playsinline>
                            <source src={IciciAnimationVideo3} type="video/mp4" />
                        </video>
                        <video width="894" height="504" className="vid mb-4" autoPlay={true} loop muted autoplay playsinline>
                            <source src={IciciAnimationVideo4} type="video/mp4" />
                        </video>
                        <p>On top of that, sound effects are synced to the actions in the video. (Eg: Sound of vehicle, the sound of notifications received, the sound of typing on phone, etc.)</p>
                        <h3>Background Music</h3>                                                                        
                        <p>In some of the cases, people recognize videos thanks to background music. Videos have animations and voice overs, but with the background music, one can surely strike the right chords with their target audience.</p>
                        <p>We are of the firm belief that a background score should be tailor-made just as it is with other elements in the video. This is the reason why we produced an original background score for Ipal chatbot.</p>
                        <p>However, a point to note: the choice of music should always be geared towards the narrative style.</p>
                        <p>There is another interesting revelation here. To enhance the emotional quotient of a viewer, we decided to switch onto more lively and encouraging music just after introducing Ipal in the video. This will enhance the value of the chatbot and its relevance to the viewer.</p>
                        <audio controls>
                            <source src={IciciAnimationAudio} type="audio/mpeg" />
                            <track kind="captions" />
                        </audio> 
                        <h3>Music Before Introducing The Ipal App</h3>
                        <p>The music at the beginning of the video is soft and has a monotonous aura to it. This encapsulates the mood of the scene, which is more about the pain points of the customers.</p>
                        <h3>Music After Introducing The Ipal App</h3>
                        <p>Once the Ipal app is introduced, the music picks up the tempo and is more optimistic. We wanted to convey with the music that the Ipal app was something to be thrilled about.</p>
                        <h3>Voice Over</h3>
                        <p>A voice-over gives an explainer video, the personality, vigor, feeling, and mood. For starters, it acts as a great source of filler with the opportunity to humanize the brand.</p>
                        <p>From our experience, we now have access to a vast pool of voice-over talents of different nationalities, accents, gender, age groups and voice tones.</p>
                        <p>Since ICICI is an Indian bank targeting Indian customers, we knew we had to use an Indian voice-over artist to better connect with the audience.</p>
                        <p><a href="https://drive.google.com/file/d/1H678tV-QcHLVfv3PsD31gCRusSsl3yG2/view?usp=sharing" target="_blank" rel="noreferrer">ICICI explainer video voice over</a></p>
                        <div className="cs-note">
                            <p className="title">NOTE</p>
                            <p>In each stage of the processes discussed above, we ask for client feedback so that they have a say in each and every aspect of the explainer video production process. If the client is not happy with something or if they want to include something more, we are more than happy to do whatever it takes to make the video better.</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default IciciProcess
