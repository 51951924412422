import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Col, Container, Row } from "react-bootstrap"

const IciciResults = () => {
  return (
    <section className="case-study cs-results">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <h2 className="font-weight-bold text-center">Results</h2>
                    <div className="sub-content mt-5">
                        <p>In Linkedin, the video reached a whopping 206346 people and was in fact a huge success on other social networking channels too.</p>
                        <StaticImage src="../../../images/case-studies/icici-bank/ICICI-Bank-LinkedIn-result.png" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid" width={800} height={458}/>
                        <p className="py-3">Whereas in Facebook, it has reached 18.8K views within the first few weeks</p>
                        <StaticImage src="../../../images/case-studies/icici-bank/ICICI-Bank-Facebook-result.png" alt="Icici Bank Explainer video Case study | WowMakers" placeholder="blurred" className="img-fluid mb-3" width={800} height={458}/>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
        
  )
}

export default IciciResults
