import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { Col, Container, Row } from "react-bootstrap"

const IciciAbout = () => {
  return (
    <section className="case-study">
        <Container>
            <Row className="justify-content-center">
                <Col lg={9}>
                    <Row className="align-items-center">
                        <Col lg={8}>
                            <div className="about-cs-product">
                                <p>The ICICI Careers Recruitment Chatbot uses state-of-the-art Artificial Intelligence, Machine Learning, and Natural Language Processing to understand user queries and provide them with the best possible responses. The Chatbot is trained to answer user queries about career opportunities with ICICI Bank. With infinite employment-related search engines in the world, Recruitment chatbot is a breath of fresh air as it serves as an up-to-date source of information about careers in ICICI.</p>
                            </div>                            
                        </Col>
                        <Col lg={4}>
                            <div className="about-company">
                                <h4>Company</h4>
                                <p>ICICI Bank is the largest private sector bank in India in terms of assets and the second-largest Indian bank in terms of assets and market capitalisation. ICICI is also listed as one of the Big Four banks of India along with State Bank of India, Bank of Baroda and Punjab National Bank.</p>
                                <p className="mb-4"><a href="https://www.icicibank.com/" target="_blank" rel="noreferrer">https://www.icicibank.com</a></p>
                                <StaticImage src="../../../images/case-studies/icici-bank/icici-bank-logo.png" alt="Icici Bank Logo | WowMakers" placeholder="blurred" className="img-fluid cs-image-min-width" width={304} height={61}/>
                            </div>                            
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </section>        
  )
}

export default IciciAbout
